import { AddSearchContextReady } from '@/contexts/search';
import AddSearchUI from 'addsearch-search-ui';
import React, { useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';
import { t } from 'ttag';
import styles from './SearchField.module.scss';
import { AUTOCOMPLETE_TEMPLATE, SEARCHFIELD_TEMPLATE } from './SearchField.templates';
import { searchIds } from './searchIds';

interface Props {
	search: AddSearchContextReady;
}

export const SearchField: React.FC<Props> = ({ search }) => {
	const ref = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		search.addSearchUI.searchField({
			containerId: searchIds.fieldId,
			template: SEARCHFIELD_TEMPLATE,
			inputAriaLabel: t`Sökfält`,
			button: t`Sök`,
			buttonAriaLabel: t`Sök`,
			searchAsYouType: true,
			placeholder: t`Sök...`,
		});

		search.addSearchUI.autocomplete({
			containerId: searchIds.autocompleteId,
			template: AUTOCOMPLETE_TEMPLATE,
			sources: [{ type: AddSearchUI.AUTOCOMPLETE_TYPE.SUGGESTIONS }],
		});
	}, [search.addSearchUI]);

	useLayoutEffect(() => {
		ref.current?.querySelector?.('input')?.focus();
	}, [search.open]);

	return (
		<div ref={ref} id={searchIds.fieldId} className={classNames(styles.container, { [styles.hidden]: !search.open })} />
	);
};
