'use client';

import { detectInvalidContent } from '@/contexts/warning';
import { ISbComponentType, ISbStoryData } from '@/types/storyblok';
import React, { useEffect, useRef } from 'react';
import { useServerPageConfig } from '../config';
import { StoryblokBridgeContext, StoryblokBridgeListener } from './internal';

export interface Props {
	children: React.ReactNode;
	stories?: ISbStoryData[];
	resolveRelations?: string[];
}

/**
 * Provider that listens to Storyblok Bridge for updates and relays the changes
 * down to each nested component that uses `useStoryblokBridge`.
 * If there is no _storyblok query parameter then the function does nothing.
 */
export const StoryblokBridgeProvider =
	process.env.NEXT_PUBLIC_PREVIEW_ENABLED && new URLSearchParams(global.location?.search).has('_storyblok')
		? StoryblokBridgeProviderEnabled
		: StoryblokBridgeProviderDisabled;

function StoryblokBridgeProviderDisabled({ children }: Props) {
	return <>{children}</>;
}

function StoryblokBridgeProviderEnabled({ children, resolveRelations = [] }: Props) {
	const listenersRef = useRef(new Set<StoryblokBridgeListener>());
	const bridgeRef = useRef<StoryblokBridgeV2>();
	const config = useServerPageConfig();

	// adds the events for updating the visual editor
	// see https://www.storyblok.com/docs/guide/essentials/visual-editor#initializing-the-storyblok-js-bridge
	function initEventListeners() {
		if (bridgeRef.current) {
			return;
		}
		const { StoryblokBridge } = window as any;
		if (typeof StoryblokBridge !== 'undefined') {
			bridgeRef.current = new StoryblokBridge({
				language: config.locale ?? 'sv',
				resolveRelations,
			});

			// Reload page on save or publish event in the Visual Editor
			bridgeRef.current!.on(['change', 'published'], () => location.reload());

			// live update the story on input events
			bridgeRef.current!.on('input', (event: any) => {
				listenersRef.current.forEach((callback) => callback(event));

				detectInvalidContent([event.story]).forEach((warning) => {
					postMessage({ type: 'invalid-content-v1', warning }, '*');
				});
			});
		}
	}

	// appends the bridge script tag to our document
	// see https://www.storyblok.com/docs/guide/essentials/visual-editor#installing-the-storyblok-js-bridge
	function addBridge(callback: any) {
		// check if the script is already present
		const existingScript = document.getElementById('storyblokBridge');
		if (!existingScript) {
			const script = document.createElement('script');
			script.src = '//app.storyblok.com/f/storyblok-v2-latest.js';
			script.id = 'storyblokBridge';
			document.body.appendChild(script);
			script.onload = () => {
				// once the scrip is loaded, init the event listeners
				callback();
			};
		} else {
			callback();
		}
	}

	useEffect(() => {
		addBridge(initEventListeners);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const value = {
		on(listener: StoryblokBridgeListener) {
			listenersRef.current.add(listener);
		},
		off(listener: StoryblokBridgeListener) {
			listenersRef.current.delete(listener);
		},
	};

	return <StoryblokBridgeContext.Provider value={value}>{children}</StoryblokBridgeContext.Provider>;
}

// https://github.com/storyblok/storyblok-js/blob/main/lib/types.ts
interface StoryblokBridgeV2 {
	pingEditor: (event: any) => void;
	isInEditor: () => boolean;
	enterEditmode: () => void;
	on: (
		event: 'customEvent' | 'published' | 'input' | 'change' | 'unpublished' | 'enterEditmode' | string[],
		callback: (payload?: ISbEventPayload) => void,
	) => void;
}

interface ISbEventPayload<S extends ISbComponentType<string> = any> {
	action: 'customEvent' | 'published' | 'input' | 'change' | 'unpublished' | 'enterEditmode';
	event?: string;
	story?: ISbStoryData<S>;
	slug?: string;
	slugChanged?: boolean;
	storyId?: number;
	reload?: boolean;
}
