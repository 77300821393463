import { ServerPageConfig } from '@/server/config';
import { normalizeFullSlug } from '@/utils/storyblok';
import {
	BreadcrumbJsonLd,
	FAQPageJsonLd,
	FAQPageJsonLdProps,
	OrganizationJsonLd,
	ProductJsonLd,
	ProductJsonLdProps,
} from 'next-seo';
import React, { createContext, useContext } from 'react';
import { ImageType, Integration, IntegrationClientType } from '../Integration';
import { useServerPageConfig } from '../config';
import { ProductSchemaData } from './types';

/**
 * H1 part 1 and part 2
 */
export const useMeta = (): StructuredData => useContext(Context);

export interface Breadcrumb {
	item: `/${string}`;
	name: string;
}

export interface StructuredData {
	product: ProductSchemaData | null;
	integration: Integration | null;
	breadcrumbs: Breadcrumb[] | null;
	faqMeta: FAQPageJsonLdProps['mainEntity'] | null;
}

const Context = createContext<StructuredData>({
	breadcrumbs: [],
	integration: null,
	product: null,
	faqMeta: [],
});

interface Props {
	children: React.ReactNode;
	config: ServerPageConfig;
	story: { path: string };
}

export const SeoProvider: React.FC<Props> = ({ children, config, story }) => {
	const product = config.product ?? null;
	const integration = config.integration ?? null;
	const breadcrumbs = config.breadcrumbs ?? [];
	const { appsHost: host } = useServerPageConfig();
	const integrationLogo = integration?.images.find((image) => image.type === ImageType.ICON);
	const integrationMainImage = integration?.images.find((image) => image.type === ImageType.HERO);
	const addGlobalSchema = story.path === '/';
	const faqMeta = config.faqMeta ?? null;

	const value: StructuredData = {
		breadcrumbs,
		product,
		integration,
		faqMeta,
	};

	let integrationData: ProductJsonLdProps | null = null;

	if (integration) {
		integrationData = {
			productName: `${integration.appName} | Fortnox Integration`,
			images: [`${host}${integrationMainImage?.path}`],
			description: integration.about,
			manufacturerName: integration.contactInformation.companyName,
			manufacturerLogo: `${host}${integrationLogo?.path}`,
			brand: integration.contactInformation.companyName,
		};

		if (integration.priceModel.clientType === IntegrationClientType.BuyableApp) {
			integrationData.offers = [
				{
					priceCurrency: 'SEK',
					price: Number(integration.priceModel.price),
					availability: 'https://schema.org/InStock',
					url: `https://www.fortnox.se/integrationer/integration/${integration.contactInformation.companyName}/${integration.appName}`,
					seller: {
						name: integration.contactInformation.companyName,
					},
				},
			];
		}
	}

	const itemListElements = breadcrumbs.map((b, i) => ({ ...b, position: i + 1 }));

	return (
		<>
			{breadcrumbs.length > 0 && <BreadcrumbJsonLd itemListElements={itemListElements} />}
			{product && (
				<ProductJsonLd
					productName={`Fortnox ${product.data.name}`}
					sku={product.data.id.toString()}
					images={[product.image]}
					description={product.data.description}
					brand="Fortnox"
					manufacturerName="Fortnox AB"
					manufacturerLogo="https://www.fortnox.se/fortnox-logo-green.png"
					offers={[
						{
							priceCurrency: 'SEK',
							price: product.data.priceStructure[12][0],
							availability: 'https://schema.org/InStock',
							url: `https://www.fortnox.se${normalizeFullSlug(product.fullSlug)}`,
							seller: {
								name: 'Fortnox AB',
							},
						},
					]}
				/>
			)}
			{integrationData && <ProductJsonLd {...integrationData} />}
			{faqMeta && <FAQPageJsonLd mainEntity={faqMeta} />}
			{addGlobalSchema && (
				<OrganizationJsonLd
					type="Corporation"
					id="https://www.fortnox.se"
					logo="https://www.fortnox.se/fortnox-logo-green.png"
					legalName="Fortnox AB"
					name="Fortnox"
					foundingDate="2001"
					address={{
						streetAddress: 'Bollgatan 3B',
						addressLocality: 'Växjö',
						postalCode: '352 46',
						addressCountry: 'SE',
					}}
					contactPoint={[
						{
							telephone: '+46-470-785000',
							contactType: 'customer service',
							areaServed: 'SE',
							availableLanguage: ['Swedish', 'English'],
						},
					]}
					sameAs={[
						'https://support.fortnox.se',
						'https://facebook.com/Fortnoxab/',
						'https://youtube.com/user/FortnoxAB',
						'https://twitter.com/fortnoxab',
						'https://vimeo.com/user104292066',
					]}
					url="https://www.fortnox.se"
				/>
			)}

			<Context.Provider value={value}>{children}</Context.Provider>
		</>
	);
};
