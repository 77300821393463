'use client';

import { ISbComponentType, ISbStoryData } from '@/types/storyblok';
import { useContext, useEffect, useState } from 'react';
import { StoryblokBridgeContext, StoryblokBridgeEvent } from './internal';

/**
 * Hook that listens to Storyblok Bridge for updates and relays the changes
 * down to each nested component that uses `useStoryblokBridge`.
 * If there is no _storyblok query parameter then the function does nothing.
 */
export const useStoryblokBridge =
	process.env.NEXT_PUBLIC_PREVIEW_ENABLED && new URLSearchParams(global.location?.search).has('_storyblok')
		? useStoryblokBridgeEnabled
		: useStoryblokBridgeDisabled;

function useStoryblokBridgeDisabled<T>(storiesOrBloks: T) {
	return storiesOrBloks;
}

function useStoryblokBridgeEnabled<T>(storiesOrBloks: T) {
	const [state, setState] = useState<T>(storiesOrBloks);
	const { on, off } = useContext(StoryblokBridgeContext);

	useEffect(() => {
		setState(storiesOrBloks);
	}, storiesOrBloks as any); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		function callback(event: StoryblokBridgeEvent) {
			setState((old) => reducer(old as any, event));
		}
		on(callback);
		return () => off(callback);
	}, [on, off]);

	return state;
}

function reducer<T extends ISbStoryData[] | ISbComponentType<any>[]>(state: T, event: StoryblokBridgeEvent): T {
	// Look for matching story
	let index = (state as ISbStoryData[])?.findIndex((story) => story.uuid === event.story.uuid);
	if (index !== -1 && !!state?.length) {
		const story = event.story;
		return [...state.slice(0, index), story, ...state.slice(index + 1)] as any;
	}
	// Look for matching blok
	index = (state as ISbComponentType<any>[])?.findIndex((blok) => blok._uid === event.story.content._uid);
	if (index !== -1 && !!state?.length) {
		const story = event.story;
		return [...state.slice(0, index), story.content, ...state.slice(index + 1)] as any;
	}
	// No match
	return state;
}
