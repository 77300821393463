// next-seo.config.ts
import type { NextSeoProps } from 'next-seo';

export const NEXT_SEO_DEFAULT: NextSeoProps = {
	title: 'Sverigeledande på webbaserad bokföring och fakturering',
	description: 'Fortnox företagsplattform effektiviserar dina administrativa sysslor, hjälper dig hitta nya kunder',
	noindex: false,
	nofollow: false,
};

export const OG_FALLBACK_IMAGE = 'https://a.storyblok.com/f/134143/1000x518/5badef6f0c/fortnox_dsf7607_green.jpg';
