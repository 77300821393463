import * as Sentry from '@sentry/nextjs';

interface ClosedPopupLeadForm {
	formId: string;
	lastClosed: number;
	closedTimes: number;
}

const cookieTTL: 'short' | 'long' = 'short';
const oneDayInMillis = 24 * 60 * 60 * 1000;

export function shouldShowGlobalPopup(previous: ClosedPopupLeadForm | undefined, duration = oneDayInMillis) {
	if (!previous) return true;

	const closedWithinLastDay = Date.now() - previous.lastClosed < duration;
	if (process.env.NODE_ENV !== 'production') {
		console.log({ closedWithinLastDay, now: Date.now(), lastClosed: previous.lastClosed, duration });
	}
	if (closedWithinLastDay) return false;

	return previous.closedTimes < 3;
}

export function getPopupCookieValues(): ClosedPopupLeadForm[] {
	const json = document.cookie
		.split('; ')
		.find((s) => s.startsWith('popup_form'))
		?.split('=')
		.pop();
	if (!json) return [];
	try {
		return JSON.parse(json);
	} catch (error) {
		Sentry.captureException(error);
		return [];
	}
}

export function getPreviouslyClosedForm(formId: string) {
	return getPopupCookieValues().find((f) => f.formId == formId);
}

export function rememberClosedPopupLeadForm(formId: string) {
	const closedPopupLeadForms = getPopupCookieValues();
	const previous = closedPopupLeadForms.find((f) => f.formId == formId);
	if (previous) {
		previous.closedTimes++;
		previous.lastClosed = Date.now();
	} else {
		closedPopupLeadForms.push({
			formId,
			lastClosed: Date.now(),
			closedTimes: 1,
		});
	}
	setCookie('popup_form', JSON.stringify(closedPopupLeadForms), cookieTTL);
}

export const getNumberTimeShown = (formId: string) => {
	const closedPopupLeadForms = getPopupCookieValues();
	const previous = closedPopupLeadForms.find((f) => f.formId == formId);

	if (!previous) {
		return 1;
	}
	return previous.closedTimes + 1;
};

function setCookie(name: string, value: string, cookieTTL: 'short' | 'long') {
	const date = new Date();
	const expireDate =
		cookieTTL === 'short'
			? new Date(date.setTime(date.getTime() + oneDayInMillis * 3))
			: new Date(date.setTime(date.getTime() + oneDayInMillis * 90));
	const expires = 'expires=' + expireDate.toUTCString();
	document.cookie = name + '=' + value + '; ' + expires + '; path=/';
}
