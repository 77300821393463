import { MetaStoryblok, OpenGraphStoryblok } from '@/components';
import { DefaultProps } from '@/server/slug';
import { ISbStoryData } from '@/types/storyblok';
import { richTextToString } from '@/utils';
import { normalizeFullSlug } from '@/utils/storyblok';
import { NEXT_SEO_DEFAULT, OG_FALLBACK_IMAGE } from '../../../next-seo.config';
import { getHeaderData } from './getHeaderData';
import { getMetaExceptions } from './getMetaExceptions';

function getTitle(
	meta: MetaStoryblok & { metaTitle: string },
	story: ISbStoryData<any>,
	dynamicMeta: DefaultProps['dynamicMeta'],
) {
	let title = NEXT_SEO_DEFAULT.title;
	const headerBlok = getHeaderData(story);
	const exceptions = getMetaExceptions(story);

	if (headerBlok?.h1Part1)
		title = headerBlok?.h1Part2 ? `${headerBlok.h1Part1.trim()} ${headerBlok.h1Part2}` : headerBlok.h1Part1;
	if (exceptions?.title) title = exceptions.title;
	if (meta?.metaTitle && meta?.metaTitle !== '') title = meta.metaTitle; // @deprecated - used as fallback
	if (meta?.title && meta?.title !== '') title = meta.title;
	if (dynamicMeta?.title) title = dynamicMeta.title;

	return title;
}

function getDescription(
	meta: MetaStoryblok & { metaDescription: string },
	story: ISbStoryData<any>,
	dynamicMeta: DefaultProps['dynamicMeta'],
) {
	let description = NEXT_SEO_DEFAULT.title;
	const headerBlok = getHeaderData(story);
	const exceptions = getMetaExceptions(story);

	if (headerBlok?.text) description = headerBlok.text;
	if (headerBlok?.shortText) description = richTextToString(headerBlok.shortText);

	if (exceptions?.description) description = exceptions.description;

	if (meta?.metaDescription && meta?.metaDescription !== '') description = meta.metaDescription; // @deprecated - used as fallback
	if (meta?.description && meta?.description !== '') description = meta.description;

	if (dynamicMeta?.description) description = dynamicMeta.description;

	return description;
}

function getImage(story: ISbStoryData<any>, openGraph: OpenGraphStoryblok, dynamicMeta: DefaultProps['dynamicMeta']) {
	const headerBlok = getHeaderData(story);
	return {
		url: dynamicMeta?.image?.path ?? openGraph?.image?.filename ?? headerBlok?.image?.filename ?? OG_FALLBACK_IMAGE!,
		alt: dynamicMeta?.image?.alt ?? openGraph?.image?.alt ?? headerBlok?.image?.alt ?? '',
	};
}

export function generateStoryMeta(story: ISbStoryData<any>, dynamicMeta: DefaultProps['dynamicMeta']) {
	const meta = story.content?.meta ? story.content.meta[0] : null;
	const openGraph = story.content?.openGraph ? story.content.openGraph[0] : null;
	const noindex = dynamicMeta?.noIndex ?? meta?.noIndex ?? NEXT_SEO_DEFAULT.noindex;

	const title = getTitle(meta, story, dynamicMeta);
	const description = getDescription(meta, story, dynamicMeta);
	const image = getImage(story, openGraph, dynamicMeta);

	return {
		...NEXT_SEO_DEFAULT,
		canonical:
			dynamicMeta?.canonical ?? normalizeFullSlug(meta?.canonical.cached_url) ?? normalizeFullSlug(story.full_slug),
		title,
		description,
		noindex,
		nofollow: meta?.noFollow ?? NEXT_SEO_DEFAULT.nofollow,
		openGraph: {
			type: 'website',
			url: dynamicMeta?.canonical
				? `https://www.fortnox.se${dynamicMeta?.canonical}`
				: `https://www.fortnox.se${normalizeFullSlug(story.full_slug)}`,
			title: openGraph?.title ?? title,
			description: openGraph?.description ?? description,
			images: [
				{
					url: image.url,
					width: 800,
					height: 600,
					alt: image.alt,
				},
			],
		},
	};
}
