import { ISbStoryData } from '@/types/storyblok';

/**
 * Traverse objects within story and return a unique set of matches
 */
export function getComponentsFromStory(story: ISbStoryData, component: string) {
	const blokArray: Record<string, any>[] = [];

	function traverse([, obj]: [string, Record<string, any>]): void {
		if (obj == null) return;

		if (typeof obj !== 'object') return;
		if (Array.isArray(obj)) {
			for (const v of obj) {
				traverse(['', v]);

				if ('component' in obj) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					if (obj?.component === component) blokArray.push(obj);
				}
			}
			return;
		}

		const isBlok = 'component' in obj;

		if (isBlok) {
			if ('component' in obj) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				if (obj.component === component) blokArray.push(obj);
			}
		}

		for (const [k, v] of Object.entries(obj)) {
			traverse([k, v]);
			if ('component' in obj) {
				if (obj.component === component) blokArray.push(obj);
			}
		}
	}

	traverse(['', story]);
	return new Set(blokArray);
}
