import { ISbStoryData } from '@/types/storyblok';

export type WarningType = 'invalid-href';

export type WarningString = `${WarningType}: ${string}`;

export function detectInvalidContent(stories: ISbStoryData[]) {
	const warnings: WarningString[] = [];

	function traverse(obj: any) {
		if (obj == null) return;
		if (['string', 'number'].includes(typeof obj)) return;
		if (Array.isArray(obj)) return obj.forEach(traverse);

		const href = obj.href;
		if (href?.includes('fnox.se')) {
			warnings.unshift(`invalid-href: ${href}`);
		}

		Object.values(obj).forEach(traverse);
	}

	for (const story of stories) traverse(story.content);

	return warnings.sort();
}
