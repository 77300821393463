import { Section } from '@/atoms';
import { useServerPageConfig } from '@/contexts/config';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import { normalizeFullSlug } from '@/utils/storyblok';
import styles from './LanguageToggle.module.scss';

interface Story extends ISbStoryData {
	lang: string;
}

interface Props {
	story: Story;
	breadcrumbTheme?: string;
	withInfoBanner?: boolean;
}

export const LanguageToggle: React.FC<Props> = ({ story, breadcrumbTheme, withInfoBanner = false }) => {
	const inEnglish = story.lang === 'en';
	const { hasSubMenuV1 } = useSubMenu();
	const { setLocale } = useServerPageConfig();

	return (
		<Section className={styles.container} padding={{ base: 'none' }}>
			<div
				className={cls(
					styles.languageSwitch,
					styles[`theme--${breadcrumbTheme}`],
					hasSubMenuV1 && styles.hasSubMenuV1,
					withInfoBanner && styles.withInfoBanner,
				)}
				onClick={() => {
					setLocale(inEnglish ? 'sv' : 'en');
				}}
			>
				<a className={styles.languageSwitchAnchor} href={normalizeFullSlug(story.full_slug, inEnglish ? 'sv' : 'en')}>
					{inEnglish ? 'Visa sidan på svenska' : 'This page is available in English'}
				</a>
			</div>
		</Section>
	);
};
