import { AddSearchContextReady } from '@/contexts/search';
import React, { useLayoutEffect } from 'react';
import styles from './SearchResults.module.scss';
import { NUMBER_OF_RESULTS_TEMPLATE } from './SearchResults.templates';
import { searchIds } from './searchIds';

interface Props {
	search: AddSearchContextReady;
}

export function SearchResults({ search }: Props) {
	useLayoutEffect(() => {
		search.addSearchUI.searchResults({
			containerId: searchIds.resultsId,
			template_resultcount: NUMBER_OF_RESULTS_TEMPLATE,
		});
	}, [search.addSearchUI]);

	return <div id={searchIds.resultsId} className={styles.container}></div>;
}
