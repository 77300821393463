import { Icon } from '@/atoms/Icon';
import { AddSearchContext } from '@/contexts/search';
import { cls } from '@/utils';
import { useEffect, useRef } from 'react';
import { t } from 'ttag';
import styles from './SearchToggleButton.module.scss';

interface Props {
	className?: string;
	search: AddSearchContext;
}

export const SearchToggleButton: React.FC<Props> = ({ className, search }) => {
	const times = useRef(0);

	useEffect(() => {
		if (times.current > 0) {
			return;
		}
		times.current++;
		const searchParams = new URLSearchParams(location.search);
		if (searchParams.has('s')) {
			search.initialize();
			search.setOpen(true);
		}
	}, [times, search]);

	return (
		<button
			type="button"
			aria-label={search.open ? t`Stäng sök` : t`Sök`}
			className={cls(styles.button, className)}
			onClick={() => search.setOpen((open) => !open)}
		>
			<Icon name={search.open ? 'xmark' : 'search'} size="2x" className={styles.icon} />
		</button>
	);
};
