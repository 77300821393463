import { ISbComponentType } from '@/types/storyblok';

export function findBlok(story: any, names: string[]): ISbComponentType<any>[] {
	function traverse(value: unknown): ISbComponentType<any>[] {
		if (value == null) return [];

		if (Array.isArray(value)) return value.flatMap(traverse);

		if (typeof value !== 'object') return [];

		const blok = value as ISbComponentType<any>;

		if (blok.component && names.includes(blok.component)) return [blok];

		return Object.values(blok).flatMap(traverse);
	}
	return traverse(story.content);
}
