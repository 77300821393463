import { Heading, Section } from '@/atoms';
import { SearchPageStoryblok } from '@/components';
import { AddSearchContextReady } from '@/contexts/search';
import { useKeyPress } from 'hooks/useKeyPress';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { t } from 'ttag';
import { SearchResults } from './SearchResults';
import styles from './SearchWidget.module.scss';

interface Props {
	search: AddSearchContextReady;
	blok?: SearchPageStoryblok;
}

export const SearchWidget: React.FC<Props> = ({ search, blok }) => {
	useKeyPress('Escape', undefined, () => {
		search.setOpen(false);
	});

	useEffect(() => {
		if (search.open && !search.ready) {
			search.initialize();
		}
	}, [search]);

	return (
		<div className={classNames(styles.container, { [styles.hidden]: !search.open })} role="search">
			<div className={styles.backdrop} />
			<div className={styles.inner}>
				<div className={styles.searchResult}>
					<button type="button" className={styles.closeButton} onClick={() => search.setOpen(false)}>
						{t`Stäng`}
					</button>
					{blok && (
						<Section>
							<Heading as="h1" title={blok.h1Part1} />
						</Section>
					)}
					<SearchResults search={search} />
				</div>
			</div>
		</div>
	);
};
