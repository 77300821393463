import { Link } from '@/atoms';
import { ISbStoryData } from '@/types/storyblok';
import { useCallback, useEffect, useState } from 'react';
import { useServerPageConfig } from '../config';
import { WarningString, detectInvalidContent } from './detectInvalidContent';

export function InvalidContentWarning({ stories }: { stories: ISbStoryData[] }) {
	const config = useServerPageConfig();
	const [anchors, setAnchors] = useState(new Map<string, HTMLAnchorElement | undefined>());
	const [warnings, setWarnings] = useWarnings(stories);

	const warningsJoined = warnings.sort().join(',');
	useEffect(() => {
		function run() {
			setAnchors(new Map(global.document ? [...document.querySelectorAll('a')].map((a) => [a.href, a]) : []));
		}

		if (document.readyState === 'complete') return void run();
		addEventListener('load', run);
		return () => removeEventListener('load', run);
	}, [warningsJoined]);

	const invalidHrefWarnings = warnings
		.filter((w) => w.startsWith('invalid-href: '))
		.map((w) => w.slice('invalid-href: '.length))
		.map((href) => {
			const url = new URL(href);
			const fixedHref = href.replace(url.origin, config.siteBaseUrl);
			return { href, anchor: anchors.get(fixedHref) };
		});

	const otherWarnings = warnings.filter((w) => !w.startsWith('invalid-href: '));

	return (
		<div
			style={{
				zIndex: 'var(--z-index-5)' as any,
				position: 'fixed',
				top: '10%',
				left: '50%',
				minWidth: '300px',
				width: '80%',
				maxWidth: '1400px',
				transform: 'translateX(-50%)',
				background: 'white',
				border: '10px solid red',
				display: warnings.length ? 'block' : 'none',
				padding: '0.5rem',
			}}
		>
			<button
				type="button"
				onClick={() => setWarnings([])}
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					border: 0,
					background: 'none',
					fontSize: '2rem',
					position: 'absolute',
					right: 0,
					top: 0,
					height: '2rem',
					width: '2rem',
					lineHeight: '2rem',
					textAlign: 'center',
					verticalAlign: 'center',
				}}
			>
				&times;
			</button>

			{invalidHrefWarnings.length > 0 && (
				<div>
					<span>
						🚧 Hoppsan, det finns några ogiltiga interna länkar på sidan. De har blivit automatiskt korrigerade på
						sidan, men behöver rättas till i Storyblok
					</span>
					<ul>
						{invalidHrefWarnings.map(({ href, anchor }, index) => (
							<li key={index}>
								<Link
									href="#"
									onClick={(e) => {
										e.preventDefault();
										anchor?.scrollIntoView({ block: 'center' });
										anchor?.focus();
									}}
								>
									Visa länk
								</Link>{' '}
								{href}
								{anchor && (
									<>
										{' '}
										&mdash; <em>{anchor?.innerText}</em>{' '}
									</>
								)}
							</li>
						))}
					</ul>
				</div>
			)}

			{otherWarnings.length > 0 && (
				<div>
					<span>🚧 Hoppsan, det finns fel på sidan som behöver rättas till i Storyblok.</span>
					<ul>
						{otherWarnings.map((warning, index) => (
							<li key={index}>{warning}</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
}

/**
 * Like useState, but it:
 * - Stores a sorted array of strings, discarding duplicate values
 * - Subscribes to changes of initial value
 * - Listens for invalid-content-v1 post message events, updating value
 */
function useWarnings(stories: ISbStoryData[]): [WarningString[], (warnings: WarningString[]) => void] {
	const input = detectInvalidContent(stories);
	const inputJoined = input.join(',');
	const [previousJoined, setPreviousJoined] = useState(inputJoined);

	const [value, setValue] = useState(input);

	const setWarnings = useCallback(
		function setWarnings(warnings: WarningString[]) {
			setValue([...new Set(warnings)].sort());
		},
		[setValue],
	);

	// Listen for invalid-content-v1 that the StoryblokBridgeEnabled will send
	// when a story is changed in Storyblok and problems are detected by
	// `detectInvalidContent`
	useEffect(() => {
		function onMessage(event: MessageEvent) {
			const warnings: WarningString[] =
				event?.data?.type === 'invalid-content-v1' ? [event.data.warning as WarningString] : [];
			if (warnings.length) setValue(warnings);
		}

		addEventListener('message', onMessage);

		return () => removeEventListener('message', onMessage);
	}, []);

	// https://beta.reactjs.org/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
	if (previousJoined !== inputJoined) {
		setPreviousJoined(inputJoined);
		setWarnings(input);
	}

	return [value, setWarnings];
}
