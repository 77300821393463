import { ISbStoryData } from '@/types/storyblok';
import { createContext } from 'react';

export const StoryblokBridgeContext = createContext<StoryblokBridgeContextProps>({
	on: () => undefined,
	off: () => undefined,
});

export interface StoryblokBridgeContextProps {
	on(listener: StoryblokBridgeListener): void;
	off(listener: StoryblokBridgeListener): void;
}

export interface StoryblokBridgeListener {
	(event: StoryblokBridgeEvent): void;
}

export interface StoryblokBridgeEvent {
	story: ISbStoryData;
}
