import { CanonicalStoryData } from '@/server/config/types';

const metaExceptionStories = ['WordPost', 'NewsPost', 'BookkeepingPost'];

export function getMetaExceptions(story: CanonicalStoryData) {
	const component = story.content?.component;
	if (!component) return;
	if (!metaExceptionStories.includes(component)) return;

	const meta: { title: string; description?: string } = { title: '', description: '' };

	if (component === 'WordPost') {
		meta.title = `Vad är ${story.name}? Definition och förklaring | Fortnox`;
		meta.description = story.content?.intro;
	}

	if (component === 'NewsPost') {
		meta.title = story.content?.header ? story.content.header[0].h1Part1 : '';
		meta.description = story.content?.intro;
	}

	if (component === 'BookkeepingPost') {
		meta.title = `Bokföra ${story.name}? – så gör du | Fortnox`;
	}

	return meta;
}
