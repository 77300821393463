import { Modal } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { UpsalesForm } from '@/bloks/Form';
import { HeaderCommon } from '@/bloks/Other';
import { GlobalPopUpStoryblok, HeaderCommonStoryblok, UpsalesFormStoryblok, UrlStoryblok } from '@/components';
import { useServerPageConfig } from '@/contexts/config';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { StoryblokStory } from 'storyblok-generate-ts';
import { useEffect, useState } from 'react';
import styles from './GlobalPopUp.module.scss';
import {
	getNumberTimeShown,
	getPreviouslyClosedForm,
	rememberClosedPopupLeadForm,
	shouldShowGlobalPopup,
} from './popupUtils';

interface BlokProps {
	blok: GlobalPopUpStoryblok;
}

const blokProps = ({ blok }: BlokProps): GlobalPopUpProps => ({
	header: blok.header,
	form: blok.form,
	segments: blok.segments,
	urls: blok.urls,
	_uid: blok._uid,
});

export interface GlobalPopUpProps {
	header: HeaderCommonStoryblok[];
	form: StoryblokStory<UpsalesFormStoryblok> | string;
	segments?: string[];
	urls?: UrlStoryblok[];
	_uid: string;
}

export const GlobalPopUp: Blok<GlobalPopUpProps, BlokProps> = ({ header, form, segments }) => {
	const isMobile = useMediaQuery('(max-width: 1023px)');
	const [showModal, setShowModal] = useState(false);
	const formId = typeof form === 'string' ? form : form.uuid;
	const [numberOfTimesShown, setNumberOfTimesShown] = useState(0);
	let { customerType } = useServerPageConfig();
	if (!customerType) customerType = 'KUNDTYP_OKÄND';

	const onClose = () => {
		rememberClosedPopupLeadForm(formId);
		setShowModal(false);
	};

	useEffect(() => {
		const openModal = () => {
			const previous = getPreviouslyClosedForm(formId);
			if (shouldShowGlobalPopup(previous)) setShowModal(true);
		};

		let timeout: NodeJS.Timeout;
		const resetTimer = () => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				openModal();
			}, 13000);
		};

		if (isMobile) {
			resetTimer();
			document.body.addEventListener('touchstart', resetTimer);
		} else {
			document.body.addEventListener('mouseleave', openModal);
		}

		setNumberOfTimesShown(getNumberTimeShown(formId));

		return () => {
			clearTimeout(timeout);
			document.body.removeEventListener('mouseleave', openModal);
			document.body.removeEventListener('touchstart', resetTimer);
		};
	}, [formId, isMobile]);

	let showPopUp = true;
	const personalized = Boolean(segments?.length);

	if (personalized) {
		showPopUp = Boolean(segments?.includes(customerType ?? '') || (customerType && segments?.includes('KUNDTYP_ALLA')));
	}

	return showPopUp ? (
		<Modal
			className={cls(styles.modal, 'piwikTrackContent')}
			show={showModal}
			onClose={() => onClose()}
			dataContentName={'Global PopUp'}
		>
			{header?.map((item) => (
				<HeaderCommon key={item._uid} {...HeaderCommon.blokProps({ blok: item })} marginBottom="xl" />
			))}
			{form && (
				<UpsalesForm
					{...UpsalesForm.blokProps({
						story: form as unknown as ISbStoryData<UpsalesFormStoryblok>,
						meta: { layout: 'twoCols', zone: 'popup', numberOfTimesShown },
					})}
				/>
			)}
		</Modal>
	) : null;
};

GlobalPopUp.blokProps = blokProps;
