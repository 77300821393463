import { ContainerFluid, Icon } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { renderRichText } from '@/bloks/RichText';
import { GlobalInfoBannerStoryblok, RichtextStoryblok } from '@/components';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import * as React from 'react';
import styles from './GlobalInfoBanner.module.scss';

interface BlokProps {
	blok: GlobalInfoBannerStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	severity: blok.severity,
	text: blok.text,
});

interface Props {
	severity?: GlobalInfoBannerStoryblok['severity'];
	text?: RichtextStoryblok;
}

function getIcon(severity: string) {
	switch (severity) {
		case 'major':
			return 'exclamation-triangle';
		default:
			return 'circle-info';
	}
}

function getBannerTheme(severity: string) {
	switch (severity) {
		case 'minor':
			return 'infoBannerMinor';
		case 'major':
			return 'infoBannerMajor';
		default:
			return 'infoBannerInfo';
	}
}

export const GlobalInfoBanner: Blok<Props, BlokProps> = ({ severity = 'info', text }) => {
	const themeInfo = getThemeInfo(getBannerTheme(severity));
	return (
		<ContainerFluid theme={getBannerTheme(severity)}>
			<div className={cls(styles.container, themeInfo.styles.bgColor)}>
				<div className={cls(styles.inner, styles[`severity--${severity}`])}>
					<Icon className={styles.icon} name={getIcon(severity)} />
					{renderRichText(text)}
				</div>
			</div>
		</ContainerFluid>
	);
};
GlobalInfoBanner.blokProps = blokProps;
