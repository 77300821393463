import { searchIds } from './searchIds';

export const SEARCHFIELD_TEMPLATE = `
  <div class="addsearch-searchfield-container">
    <form class="addsearch-searchfield" autocomplete="off" action="?" role="search">
      <div class="search-field-wrapper">
        <input
          itemprop="query-input"
          list="${searchIds.datalistId}"
          type="search"
          placeholder="{{placeholder}}"
          aria-label="{{inputAriaLabel}}"
          class="{{#not icon false}}icon{{/not}}"
        />
      </div>
      {{#if button}}
        <button type="button" aria-label="{{buttonAriaLabel}}">{{button}}</button>
      {{/if}}
    </form>
    <div id="addsearch-ui-autocomplete" class="addsearch-autocomplete"></div>
  </div>
`;

export const AUTOCOMPLETE_TEMPLATE = `
  {{#gt suggestions.length 0}}
    <ul class="suggestions">
      {{#each ../suggestions}}
        <li data-keyword="{{value}}" data-index="{{@index}}" {{#equals ../../activeSuggestionIndex @index}}class="active"{{/equals}}>
          {{value}}
        </li>
      {{/each}}
    </ul>
  {{/gt}}
  {{#gt customFields.length 0}}
    <ul class="suggestions">
      {{#each ../customFields}}
        <li data-keyword="{{value}}" data-index="{{@index}}" {{#equals ../../activeSuggestionIndex @index}}class="active"{{/equals}}>
          {{value}}
        </li>
      {{/each}}
    </ul>
  {{/gt}}
`;
